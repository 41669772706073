import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useCampaigns, usePrompt } from 'hooks';
import { tkCampaignType } from 'i18n/translationKeyMaps';
import {
  CSP_EXECUTION_ALLOWED_STATES,
  STATE_TO_MESSAGE_MAPPING,
} from 'shared/constants';
import { CSP_EXECUTION_ALLOWED_CAMPAIGN_TYPES } from 'shared/constants/csp-execution-allowed-campaign-types';
import { CSP_EXECUTION_ALLOWED_UNIVERSE_BUILD_TYPES } from 'shared/constants/csp-execution-allowed-universe-build-types';
import { ExtendedCampaign } from 'shared/interfaces/Campaign';
import { FormType, UserRole } from 'types';
import { definitions } from 'types/api';
import { campaignTypeGuard } from 'types/helpers';
import { objectKeys } from 'utils';
import { useFeature } from 'utils/FeatureFlags';

import { Routes } from 'routes/Routes';

import { useStoreDispatch } from 'store';
import {
  useCloneCampaignMutation,
  useCreateCampaignMutation,
  useDeleteCampaignMutation,
  useGetCampaignByIdQuery,
  useUpdateCampaignMutation,
} from 'store/api/endpoints/campaign';
import {
  useGetCSPByCampaignIdQuery,
  useLazyCreateCSPExecutionQuery,
} from 'store/api/endpoints/campaignSuccessPrediction';
import { useGetCumulativesQuery } from 'store/api/endpoints/reports';
import {
  useGetUniverseByIdQuery,
  useLazyGetUniverseByIdQuery,
} from 'store/api/endpoints/universes';
import {
  selectCampaignDetailsAlerts,
  selectDraftCampaign,
  selectDraftHasChanges,
  selectDraftTouchedFields,
  selectDraftValidations,
} from 'store/modules/campaign/selectors';
import {
  addCampaignAlert,
  removeAllCampaignAlerts,
  removeCampaignAlert,
  setDraft as setDraftCampaign,
  setDraftTouchedFields,
  updateDraft,
} from 'store/modules/campaign/slice';
import { Validation } from 'store/modules/campaign/validations';
import { addNotification } from 'store/modules/notifications/actions';
import {
  selectChannels,
  selectCountries,
} from 'store/modules/settings/selectors';
import { selectUser } from 'store/modules/user/selectors';
import { setUserAdvertiserId } from 'store/modules/user/slice';

import {
  CampaignFormStatus,
  useCampaignFormState,
} from 'hooks/useCampaignFormState';

import { RejectReasonDialog } from 'components/Campaigns/CampaignDetails/sections/RejectReasonDialog';
import { AccessResolver } from 'components/Shared/AccessResolver/AccessResolver';
import {
  Alert,
  InputValidationMessage,
  PageHeader,
} from 'components/Shared/CustomMui';
import { AlertProps } from 'components/Shared/CustomMui/Alert/Alert';
import { DialogWrapper } from 'components/Shared/CustomMui/DialogWrapper/DialogWrapper';
import { DiscardConfirmationDialog } from 'components/Shared/DiscardConfirmationDialog/DiscardConfirmationDialog';
import LoadingIndicator from 'components/Shared/LoadingIndicator/LoadingIndicator';

import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import { CloneCampaign } from '../CloneCampaignDialog/CloneCampaign';
import styles from './CampaignDetails.module.scss';
import { CampaignOverview } from './Overview/Overview';
import { OverviewActionButtons } from './Overview/components/OverviewActionButtons';
import { Budget } from './sections';
import { ChangeLog } from './sections/ChangeLog/ChangeLog';
import Channels from './sections/Channels';
import { CreativesWrapper } from './sections/Creatives/CreativesWrapper';
import Details from './sections/Details';
import FrequencyCap from './sections/FrequencyCap/FrequencyCap';
import Products from './sections/Products/Products';
import UniverseSection from './sections/Universes/UniverseSection';
import Window from './sections/Window';

export interface CampaignSectionProps {
  formState: CampaignFormStatus;
  validate: (
    campaignField: keyof ExtendedCampaign,
    errorPlaceholder?: any
  ) => InputValidationMessage;
}

export interface CampaignDetailsLocationState {
  groupId?: string;
  countryCode?: string;
}

interface Props {
  formType: FormType;
}

const CampaignDetails = ({ formType }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useStoreDispatch();
  const { campaignId, campaignType } = useParams();
  const location = useLocation();
  const locationState = location.state as CampaignDetailsLocationState;
  const alerts = useSelector(selectCampaignDetailsAlerts);

  const user = useSelector(selectUser);
  const networkChannels = useSelector(selectChannels);
  const countries = useSelector(selectCountries);
  const hasChanges = useSelector(selectDraftHasChanges);
  const draftCampaign = useSelector(selectDraftCampaign);
  const validations = useSelector(selectDraftValidations);
  const touchedFields = useSelector(selectDraftTouchedFields);

  const isCSPEnabled = useFeature('isCSPEnabled') as boolean;
  const { showPrompt, confirmNavigation, cancelNavigation } = usePrompt(false); // hasChanges && !safeToNavigate

  /**
   * useGetCumulativesQuery can accept multiple campaign ids,
   * but as we are currently in campaign detail page,
   * we can just pass one campaign received from useParams().
   */
  const { data: cumulatives } = useGetCumulativesQuery(
    campaignId ? [campaignId] : [],
    {
      skip: !campaignId,
    }
  );

  const typedCampaignType = campaignTypeGuard(campaignType || '');

  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [openStopConfirmation, setOpenStopConfirmation] =
    useState<boolean>(false);
  const [openRejectReasonDialog, setOpenRejectReasonDialog] = useState(false);
  const [isCloneDialogOpen, setIsCloneDialogOpen] = useState<boolean>(false);
  const [shouldExecuteCSP, setShouldExecuteCSP] = useState<boolean>(true);
  const [campaignStateBeforeTransition, setCampaignStateBeforeTransition] =
    useState<definitions['CampaignState']>(
      draftCampaign?.state || 'CAMPAIGN_STATE_DRAFT'
    );
  /**
   * Whenever we save the campaign, the query will be called automatically.
   * Because, we invalidate the cache for this campaign when we save it.
   */
  const { data: campaignQueryData, isLoading: isLoadingCampaign } =
    useGetCampaignByIdQuery(campaignId!, {
      skip: !campaignId,
      refetchOnMountOrArgChange: true,
    });

  const [triggerCreateCSPExecution, createCSPExecutionResult] =
    useLazyCreateCSPExecutionQuery();

  // fetch previous success prediction if there is no ongoing prediction
  const { data: successPrediction } = useGetCSPByCampaignIdQuery(
    { campaignId: campaignId! },
    {
      skip:
        !isCSPEnabled ||
        !campaignId ||
        !!createCSPExecutionResult?.data?.execution_id,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: universeData, isLoading: isLoadingUniverse } =
    useGetUniverseByIdQuery(
      { id: draftCampaign?.universe_id!, silentNotification: false },
      {
        skip: !draftCampaign || !draftCampaign.universe_id,
        refetchOnMountOrArgChange: true,
      }
    );
  const [getUniverseById] = useLazyGetUniverseByIdQuery();

  const [createCampaign, { isLoading: isCreating }] =
    useCreateCampaignMutation();
  const [updateCampaign, { isLoading: isUpdating }] =
    useUpdateCampaignMutation();
  const [deleteCampaign, { isLoading: isDeleting }] =
    useDeleteCampaignMutation();
  const [cloneCampaign, { isLoading: isCloningCampaign }] =
    useCloneCampaignMutation();

  const updatingCampaignEntity =
    isCreating || isUpdating || isDeleting || isCloningCampaign;

  const isLoading = isLoadingCampaign || isLoadingUniverse;

  const campaign: ExtendedCampaign = useCampaigns(
    !draftCampaign ? [] : [draftCampaign],
    cumulatives as definitions['CumulativeMetric'][],
    networkChannels,
    draftCampaign?.universe_id ? universeData : undefined
  )?.[0];

  const originalFormState = useCampaignFormState({
    formType,
    state: updatingCampaignEntity
      ? campaignStateBeforeTransition
      : campaign?.state,
    type: campaign?.type,
    userRole: user?.role as UserRole,
  });

  const getMessageObjectBasedOnState = (
    state: definitions['CampaignState']
  ): AlertProps | undefined => {
    return STATE_TO_MESSAGE_MAPPING.hasOwnProperty(state)
      ? STATE_TO_MESSAGE_MAPPING[state]
      : undefined;
  };

  const isASubmittedConversionCampaign =
    originalFormState.campaignState === 'CAMPAIGN_STATE_SUBMITTED' &&
    campaign.type === 'CAMPAIGN_TYPE_CONVERSION';

  const formActions = () => {
    const restrictSaveWithRole =
      (user?.role as UserRole) &&
      [UserRole.SALESFORCE_MANAGER, UserRole.APPROVAL_MANAGER].includes(
        user?.role as UserRole
      );
    if (restrictSaveWithRole) {
      return (campaign && !!campaign.external_campaign_id) ||
        isASubmittedConversionCampaign
        ? originalFormState.formActions
        : originalFormState.formActions.filter((action) => action !== 'save');
    }
    return originalFormState.formActions;
  };

  const formState = { ...originalFormState, formActions: formActions() };

  useEffect(() => {
    Object.keys(STATE_TO_MESSAGE_MAPPING).forEach((state: string) =>
      dispatch(
        removeCampaignAlert(
          getMessageObjectBasedOnState(
            state as definitions['CampaignState']
          ) as AlertProps
        )
      )
    );
    if (!!getMessageObjectBasedOnState(campaign?.state)) {
      dispatch(
        addCampaignAlert(
          getMessageObjectBasedOnState(campaign?.state) as AlertProps
        )
      );
    }
    if (!!campaign?.ab_testing_cloned_campaign_id) {
      dispatch(
        addCampaignAlert({
          text: (
            <>
              {t('ab_testing_header_message')}
              <Link
                className={styles.text_link}
                onClick={() =>
                  navigate(
                    `${Routes.CAMPAIGNS}/${campaign.ab_testing_cloned_campaign_id}/edit`
                  )
                }
              >
                {campaign?.ab_testing_cloned_campaign_name}
              </Link>
            </>
          ),
          severity: 'warning',
        })
      );
    }
    if (campaign?.universe?.build.status === 'UNIVERSE_BUILDER_STATUS_FAIL') {
      dispatch(
        addCampaignAlert({
          text: t('failed_universe_message'),
          severity: 'error',
        })
      );
    }
    return () => {
      dispatch(removeAllCampaignAlerts());
    };
  }, [
    campaign?.universe,
    campaign?.universe_id,
    campaign?.state,
    campaign?.ab_testing_cloned_campaign_id,
  ]);

  useEffect(() => {
    const setup = async () => {
      if (formType === 'create') {
        if (typedCampaignType === 'CAMPAIGN_TYPE_UNKNOWN')
          navigate(Routes.ERROR, { state: { error: { status: '404' } } });
        dispatch(
          setDraftCampaign({
            id: 'create',
            type: typedCampaignType,
            extended_channels: networkChannels,
          })
        );
        return;
      }
      dispatch(
        setDraftCampaign({
          id: campaignId!,
          type: typedCampaignType,
          extended_channels: networkChannels,
          countries: countries,
        })
      );
    };
    setup();
    return () => {
      dispatch(setDraftCampaign(null));
    };
  }, [campaignId, campaignQueryData, dispatch, formType, typedCampaignType]);

  useEffect(() => {
    if (campaign && campaign.used_budget_total_cumulative)
      dispatch(
        updateDraft({
          used_budget_total_cumulative: campaign?.used_budget_total_cumulative,
        })
      );
  }, [campaign?.used_budget_total_cumulative]);

  useEffect(() => {
    if (campaign?.advertiser_id)
      dispatch(setUserAdvertiserId(campaign?.advertiser_id));
  }, [campaign?.advertiser_id]);

  const isValidByUserRole = (userRole: UserRole) => {
    let userRoleBasedValidators: (keyof typeof validations)[] = [];
    // set user role validators
    switch (userRole) {
      case UserRole.KEY_ACCOUNT_MANAGER:
      case UserRole.ADVERTISER_ADMIN:
      case UserRole.ADVERTISER_CAMPAIGN_MANAGER: {
        userRoleBasedValidators = objectKeys(validations).filter(
          (fieldValidator) =>
            fieldValidator !== 'incentive_distribution' &&
            fieldValidator !== 'reject_reason'
        );
        break;
      }
      case UserRole.APPROVAL_MANAGER: {
        userRoleBasedValidators = ['incentive_distribution'];
        break;
      }
    }

    // check that all validators are valid for the user role
    return !userRoleBasedValidators
      .filter((validator) => !!validations[validator])
      .some(
        (validator) =>
          Object.values(validations[validator]!).filter((value) =>
            Array.isArray(value)
              ? (value as boolean[]).some((v) => v) // if it is an array, check if any of the values is true
              : !!value
          ).length
      );
  };

  // check the validity only for the draft & rejected campaigns
  const isCampaignValid = isValidByUserRole(user?.role as UserRole);

  const disableSaveButtonByUserRole = (userRole: UserRole): boolean => {
    switch (userRole) {
      case UserRole.ADVERTISER_CAMPAIGN_MANAGER:
      case UserRole.ADVERTISER_ADMIN:
      case UserRole.KEY_ACCOUNT_MANAGER: {
        return !user?.advertiser_id || !hasChanges;
      }
      case UserRole.SALESFORCE_MANAGER:
      case UserRole.APPROVAL_MANAGER: {
        return !hasChanges || !!validations['incentive'];
      }
      default: {
        return true;
      }
    }
  };

  const disableSave = disableSaveButtonByUserRole(user?.role as UserRole);

  const saveCampaign = async () => {
    if (!canSaveCampaign()) {
      dispatch(
        addNotification({
          text: t('form_validation_error'),
          type: 'error',
        })
      );
      return;
    }

    const unwrapResult = await (formType === 'create'
      ? createCampaign()
      : updateCampaign()
    ).unwrap();

    dispatch(
      addNotification({
        text:
          formType === 'create' ? t('campaign_created') : t('campaign_updated'),
        type: 'success',
      })
    );
    if (formType === 'create') {
      navigate(`${Routes.CAMPAIGNS}/${unwrapResult.id}/edit`, {
        replace: true,
      });
    } else if (formType === 'edit') {
      // This disables the save button after successfully persisting the changes
      dispatch(setDraftCampaign(unwrapResult));
    }

    // request a new success prediction
    if (canExecuteCSP(campaign, campaign.universe) && unwrapResult.state) {
      setShouldExecuteCSP(true);
      await triggerCreateCSPExecution(unwrapResult.id);
    }
  };

  useEffect(() => {
    if (formType === 'create' && locationState) {
      dispatch(setDraftTouchedFields('campaign_group_id'));
      dispatch(
        updateDraft({
          campaign_group_id: locationState.groupId,
          country_code: locationState.countryCode,
        })
      );
    }
  }, []);

  const isCSPReadyForExecution = (
    campaign: ExtendedCampaign,
    universe?: definitions['Universe']
  ): boolean => {
    return (
      CSP_EXECUTION_ALLOWED_CAMPAIGN_TYPES.includes(campaign.type) &&
      (!universe ||
        CSP_EXECUTION_ALLOWED_UNIVERSE_BUILD_TYPES.includes(
          universe?.build?.type
        )) && // PACMAN-844 - PACMAN-2381
      CSP_EXECUTION_ALLOWED_STATES.includes(campaign.state) &&
      !!campaign?.budget &&
      !!campaign?.campaign_start &&
      !!campaign?.promoted_products?.length &&
      (!!campaign?.advertiser_cpa_bid ||
        !!campaign?.advertiser_cpm_bid ||
        !!campaign?.advertiser_cpc_bid)
    );
  };

  const canExecuteCSP = (
    campaign: ExtendedCampaign,
    universe?: definitions['Universe']
  ): boolean => {
    return isCSPEnabled && isCSPReadyForExecution(campaign, universe);
  };

  const canSaveCampaign = (): boolean => {
    dispatch(setDraftTouchedFields('name'));
    return (
      !!validations.name && validations.name[Validation.NAME_EMPTY] === false
    );
  };

  const submitCampaign = async () => {
    revalidateAllFields();
    if (!isCampaignValid) {
      dispatch(
        addNotification({
          text: t('form_validation_error'),
          type: 'error',
        })
      );
      return;
    }
    changeCampaignState('CAMPAIGN_STATE_SUBMITTED', t('campaign_submitted'));
  };

  const approveCampaign = async () => {
    if (!isCampaignValid) {
      dispatch(
        addNotification({
          text: t('form_validation_error'),
          type: 'error',
        })
      );
      return;
    }

    dispatch(
      updateDraft({
        reject_reason: '',
      })
    );
    await changeCampaignState(
      'CAMPAIGN_STATE_APPROVED',
      t('campaign_approved')
    );
  };

  const handleDelete = async () => {
    try {
      if (!campaign) return;
      await deleteCampaign(campaign.id);
      dispatch(
        addNotification({
          text: t('campaign_deleted'),
          type: 'success',
        })
      );

      if (location.key === 'default') {
        // if there is no history go to the campaigns page
        navigate(Routes.CAMPAIGNS);
      } else {
        navigate(-1);
      }
    } finally {
      setOpenDeleteConfirmation(false);
    }
  };

  const getFieldValidationMessage = (
    key: keyof ExtendedCampaign,
    errorPlaceholder?: any
  ): InputValidationMessage => {
    const campaignFieldValidation = validations[key];
    let errorMessage: undefined;
    if (campaignFieldValidation) {
      objectKeys(campaignFieldValidation).forEach((v) => {
        if (
          !Array.isArray(campaignFieldValidation[v]) &&
          campaignFieldValidation[v] !== false
        ) {
          errorMessage = t(v, errorPlaceholder);
        }
      });
    }
    return (
      formState.showValidations &&
      touchedFields[key] &&
      !!validations[key] &&
      errorMessage
    );
  };

  const getHeaderText = () => {
    switch (formType) {
      case 'create':
        return t('create_campaign', {
          type: tkCampaignType(typedCampaignType),
        });
      case 'edit':
        return t('edit_campaign');
      case 'validate':
        return t('validate_campaign');
      default:
        return t('view_campaign');
    }
  };

  const revalidateAllFields = (): boolean => {
    objectKeys(campaign).forEach((key) => {
      dispatch(setDraftTouchedFields(key));
    });

    return isValidByUserRole(user?.role as UserRole);
  };

  const cloneConfirmationHandler = async (
    cloneCampaignName: string,
    abTest: boolean
  ): Promise<void> => {
    setIsCloneDialogOpen(false);
    const cloneCampaignResponse = await cloneCampaign({
      path: { source_id: campaign?.id },
      body: { body: { name: cloneCampaignName, abTest } },
    }).unwrap();
    if (cloneCampaignResponse) {
      const universeForClonedCampaign = cloneCampaignResponse.campaign
        .universe_id
        ? await getUniverseById({
            id: cloneCampaignResponse.campaign.universe_id,
            silentNotification: false,
          }).unwrap()
        : undefined;

      if (
        canExecuteCSP(
          cloneCampaignResponse.campaign,
          universeForClonedCampaign
        ) &&
        cloneCampaignResponse.campaign.state
      ) {
        await triggerCreateCSPExecution(cloneCampaignResponse.campaign.id);
      }
      navigate(
        `${Routes.CAMPAIGNS}/${cloneCampaignResponse?.campaign?.id}/edit`,
        {
          replace: true,
        }
      );
    }
  };

  const rejectCampaign = async () => {
    const changingStateResult = await changeCampaignState(
      'CAMPAIGN_STATE_REJECTED',
      t('campaign_rejected')
    );
    if (changingStateResult) setOpenRejectReasonDialog(false);
  };

  const changeCampaignState = async (
    nextState: definitions['CampaignState'],
    customMessage?: string
  ): Promise<boolean> => {
    const currentState = campaign.state;
    setCampaignStateBeforeTransition(currentState);

    dispatch(
      updateDraft({
        state: nextState,
      })
    );

    try {
      const updatedCampaign = await updateCampaign().unwrap();
      if (updatedCampaign) {
        dispatch(
          addNotification({
            text: customMessage || t('campaign_updated'),
            type: 'success',
          })
        );
      }
      return true;
    } catch {
      dispatch(updateDraft({ state: currentState }));
      return false;
    } finally {
      setOpenStopConfirmation(false);
    }
  };

  return (
    <>
      <LoadingIndicator isAppLoading={isCloningCampaign || isLoading} />
      <PageHeader
        headerText={getHeaderText()}
        subHeaderText={t('subheader_edit_campaign')}
        parentLinkText={t('back').toUpperCase()}
        parentLink={location.key === 'default' ? Routes.CAMPAIGNS : -1}
      >
        <AccessResolver
          requiredRoles={[
            UserRole.SALESFORCE_MANAGER,
            UserRole.APPROVAL_MANAGER,
          ]}
          reverseRoleCheck={true}
        >
          {alerts?.map((alert: AlertProps, index: number) => (
            <Alert
              key={alert.severity + index}
              text={alert.text}
              severity={alert.severity}
            />
          ))}
        </AccessResolver>
      </PageHeader>

      {campaign && !isLoading && (
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={7}>
            <Details
              name={campaign.name}
              countryCode={campaign.country_code}
              advertiserId={
                formType === 'create'
                  ? user?.advertiser_id!
                  : campaign.advertiser_id
              }
              campaignGroupId={campaign.campaign_group_id}
              formState={formState}
              validate={getFieldValidationMessage}
              resetCSP={() => setShouldExecuteCSP(false)}
            />

            <Channels
              campaignChannels={campaign.channels}
              formState={formState}
              campaignType={typedCampaignType}
              validate={getFieldValidationMessage}
              countryCode={campaign.country_code}
            />

            <Products
              promotedProductGTINs={campaign.promoted_products || []}
              additionallyPromotedProductGTINs={
                campaign.additionally_promoted_products || []
              }
              formState={formState}
              validate={getFieldValidationMessage}
              retailer_ids={[
                campaign.extended_channels?.[0]?.retailer_id as string,
              ]}
              countryCode={campaign.country_code}
            />

            {!formState.hiddenFields.includes('Universes') && (
              <UniverseSection
                universe={campaign.universe}
                formState={formState}
                validate={getFieldValidationMessage}
                retailerId={campaign.extended_channels?.[0]?.retailer_id}
              />
            )}

            <Window
              attribution_window_end={campaign.attribution_window_end}
              campaign_start={campaign.campaign_start}
              campaign_end={campaign.campaign_end}
              countryCode={campaign?.country_code}
              state={campaign.state}
              formState={formState}
              validate={getFieldValidationMessage}
              selectedProductGtins={
                campaign.promoted_products?.concat(
                  campaign.additionally_promoted_products || []
                ) || []
              }
              retailerId={
                campaign.extended_channels?.[0]?.retailer_id as string
              }
            />

            {!formState.hiddenFields.includes('FrequencyCap') && (
              <FrequencyCap
                formState={formState}
                businessRuleLimit={campaign.business_rules_limit}
                validate={getFieldValidationMessage}
              />
            )}

            <Budget
              formState={formState}
              campaign={campaign}
              validate={getFieldValidationMessage}
            />

            <CreativesWrapper
              campaignCreatives={campaign.creatives}
              channel={campaign.extended_channels?.[0]}
              formState={formState}
              validate={getFieldValidationMessage}
              countryCode={campaign.country_code}
              isTargetUrlRequired={campaign?.type === 'CAMPAIGN_TYPE_CLICK'}
            />
            {formType === 'edit' && (
              <ChangeLog
                campaignId={campaignId as string}
                campaignType={campaign.type}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <CampaignOverview
              campaign={campaign}
              formType={formType}
              state={
                updatingCampaignEntity
                  ? campaignStateBeforeTransition
                  : campaign.state
              }
              formState={formState}
              cspExecutionId={
                createCSPExecutionResult?.currentData?.campaign_id ===
                  campaign.id && shouldExecuteCSP
                  ? createCSPExecutionResult?.data?.execution_id
                  : undefined
              }
              prevCSPExecutionId={
                successPrediction?.campaign_id === campaign.id &&
                shouldExecuteCSP
                  ? successPrediction?.execution_id
                  : undefined
              }
              cloneCampaign={() => setIsCloneDialogOpen(true)}
              validate={getFieldValidationMessage}
            >
              <OverviewActionButtons
                formState={formState}
                disabled={updatingCampaignEntity}
                formType={formType}
                disableSave={disableSave}
                submittingState={updatingCampaignEntity ? 'Submitting' : 'Idle'}
                saveCampaign={() => {
                  // setSafeToNavigate(true);
                  saveCampaign();
                }}
                submitCampaign={submitCampaign}
                approveCampaign={() => {
                  // setSafeToNavigate(true);
                  approveCampaign();
                }}
                deleteCampaign={() => setOpenDeleteConfirmation(true)}
                rejectCampaign={() => setOpenRejectReasonDialog(true)}
                withdrawCampaign={() =>
                  changeCampaignState('CAMPAIGN_STATE_DRAFT')
                }
                pauseCampaign={() =>
                  changeCampaignState('CAMPAIGN_STATE_PAUSED_MANUAL')
                }
                resumeCampaign={() =>
                  changeCampaignState('CAMPAIGN_STATE_RUNNING')
                }
                stopCampaign={() => setOpenStopConfirmation(true)}
              />
            </CampaignOverview>
          </Grid>
        </Grid>
      )}

      {(openDeleteConfirmation || openStopConfirmation) && (
        <DialogWrapper
          width={550}
          headerText={t(
            openDeleteConfirmation ? 'delete_campaign' : 'stop_campaign'
          )}
          handleClose={() =>
            openDeleteConfirmation
              ? setOpenDeleteConfirmation(false)
              : setOpenStopConfirmation(false)
          }
          dialogContent={t(
            openDeleteConfirmation
              ? 'delete_campaign_description'
              : 'stop_campaign_description',
            {
              name: campaign.name,
            }
          )}
          dialogActions={
            <>
              <Button
                onClick={() =>
                  openDeleteConfirmation
                    ? setOpenDeleteConfirmation(false)
                    : setOpenStopConfirmation(false)
                }
                variant="outlined"
              >
                {t('cancel')}
              </Button>
              <LoadingButton
                loading={isDeleting || isUpdating}
                onClick={() =>
                  openDeleteConfirmation
                    ? handleDelete()
                    : changeCampaignState('CAMPAIGN_STATE_STOPPED')
                }
                variant="contained"
              >
                {openDeleteConfirmation ? t('delete') : t('stop')}
              </LoadingButton>
            </>
          }
        />
      )}

      {openRejectReasonDialog && (
        <RejectReasonDialog
          disableSave={
            !!validations['reject_reason']?.['V REJECT_REASON_EMPTY']
          }
          rejectReason={campaign?.reject_reason}
          onConfirm={rejectCampaign}
          onCancel={() => setOpenRejectReasonDialog(false)}
        />
      )}
      {isCloneDialogOpen && (
        <CloneCampaign
          onClose={() => setIsCloneDialogOpen(false)}
          campaignToClone={campaign as ExtendedCampaign}
          cloneConfirmationHandler={(name, isABtest) => {
            // setSafeToNavigate(true);
            cloneConfirmationHandler(name, isABtest);
          }}
        />
      )}

      {showPrompt && (
        <DiscardConfirmationDialog
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
      )}
    </>
  );
};

export default CampaignDetails;
