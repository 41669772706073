import { UserRole } from 'shared/enums';
import { ErrorCode } from 'types';

import {
  CREATIVE_ALTERNATIVE_TEXT_MAX_LENGTH,
  CREATIVE_TARGET_URL_MAX_LENGTH,
  MINIMUM_CAMPAIGN_DURATION_DAYS,
  Validation,
} from 'store/modules/campaign/validations';

const en = {
  translation: {
    ab_test: 'A/B Test',
    ab_testing_header_message:
      'This campaign is part of an A/B test. Do not alter campaign parameters other than the creative, since it will break the A/B test! \n You can find the linked campaign here: ',
    ad_not_clickable_warning:
      'At least one creative of this campaign does not have a target URL. Only creatives with incentive or target URL are clickable. According to the current settings, not all shoppers will receive an incentive, therefore these ads will not be clickable.',

    add_campaign_to_group: 'Add campaigns',
    add_criteria: 'ADD CRITERIA',
    add_user: 'Add new user',
    add_section: 'Add Section',
    add_rule: 'Add Rule',
    add_section_message:
      'Add a section to create an OR connection between the rule sets.\n “OR” means that either of the rule sets needs to be fulfilled.',
    add_rule_message:
      'You can add multiple criteria here. They will be connected with an AND connection. That means that all criteria need to be fulfilled.',
    additional_product_hint:
      'Items that are accounted for because they are in the same item group:',
    actions: 'Actions',
    ad_block_detected:
      'Please disable ad blocker for this site and refresh the page.',
    add_advertiser: 'Add Advertiser',
    add_new_advertiser: 'Add New Advertiser',
    add_new_advertiser_subheader:
      'Add advertiser here for them to be able to use the application.',
    add_campaign: 'Add Campaign',
    add_creative: 'Add Creative',
    add: 'Add',
    add_new: 'Add new',
    add_products: 'Add products',
    add_product_notification:
      '[{{product_id}}] {{product_name}} has been added successfully',
    admin_email: 'Admin Email',
    admin_name: 'Admin Name',
    advertiser_name: 'Advertiser Name',
    advertiser_id: 'Advertiser Id',
    advertiser_contact_email: 'Advertiser Contact Email',
    advertiser_id_allowed_pattern_hint:
      'Only a maximum of 30 letters, numbers, and hyphens are allowed.',
    advertiser_created_message: 'Advertiser has been successfully created!',
    advertiser_id_exists_error: 'Advertiser already exists!',
    after: 'after',
    all: 'All',
    all_advertisers: 'All Advertisers',
    alternative_text: 'Creative Text',
    and: 'And',
    apply: 'Apply',
    approve: 'Approve',
    approval: 'Approval',
    assign_channel: 'Assign channel',
    assign_universe: 'Assign Audience',
    assigned: 'Assigned',
    attribution_window: 'Attribution window',
    attributes: 'Attributes',
    average_cpa: 'Average CPA',
    average_cpc: 'Average CPC',
    average_cpm: 'Average CPM',
    back: 'Back',
    bid: 'Bid',
    bid_modifier_title: 'Bid Modifier',
    bid_modifier_tooltip:
      'Please note that by applying a bid modifier you will interfere in the auction mechanism which may also impact the delivery of other campaigns. Your changes will not take effect until the next day.',
    blocks: 'Blocks',
    block_distribution_for: 'Block distribution for',
    bought_products: 'Bought products',
    buyers_of: 'buyers of',
    back_to_universe: 'Back to audience',
    brand: 'Brand',
    browse_files: 'Browse files',
    budget: 'Budget',
    budget_depleted_message:
      'The budget for this campaign is depleted. As soon as more budget is allocated, it will automatically resume after you have saved it.',
    budget_successfully_saved: 'Budget successfully saved.',
    budget_ratio: 'Budget Ratio (%)',
    business_name: 'Business Name',
    buyers_of_product: 'buyers of {{productCount}} products',
    buyers_of_attribute: 'buyers of {{attributeCount}} attributes',
    buyers_of_attribute_and_search_term:
      'buyers of {{attributeCount}} attributes and "{{searchQuery}}"',
    calculation_in_progress: 'Calculation in progress',
    campaign_bid_modifier_decrease: 'decrease',
    campaign_bid_modifier_neutral: 'neutral',
    campaign_bid_modifier_boost: 'boost',
    cpm_bid: 'CPM',
    cpa_bid: 'CPA',
    cpc_bid: 'CPC',
    clicks: 'Clicks',
    calculation_started:
      'The calculation is in progress. The process can take up to one minute...',
    calculation_failed:
      'Unfortunately the prediction is unsuccessful, please try to update',
    calculation_finished: `The {{metric}} changed {{from}} to {{to}}`,
    campaign: 'Campaign',
    campaign_group: 'Campaign Group',
    campaign_group_metric_header_title: 'Campaign Group Total',
    campaign_group_updated: 'Campaign group updated.',
    campaign_group_overview_title: 'Campaign Group Overview',
    campaign_group_overview_hint_for_saving:
      'If you change the budget in the table below, it will be saved automatically (no need to click the save button again). ',
    campaign_group_overview_hint_for_deleting:
      'Please note that by deleting the campaign group, only the assignment to the group will be deleted, not the campaigns themselves. ',
    campaign_groups: 'Campaign Groups',
    campaign_name: 'Campaign Name',
    campaigns: 'Campaigns',
    campaign_approved: 'Campaign approved',
    campaign_created: 'Campaign created',
    campaign_duration: 'campaign duration',
    campaign_overview: 'Campaign Overview',
    campaign_updated: 'Campaign updated',
    campaign_submitted: 'Campaign submitted',
    campaign_deleted: 'Campaign deleted',
    campaign_details: 'Campaign Details',
    campaign_rejected: 'Campaign rejected',
    campaign_type: 'Campaign Type',
    campaign_start: 'Campaign Start',
    campaign_end: 'Campaign End',
    campaign_paused_message:
      'This campaign is paused manually. You can resume it any time.',
    campaign_type_selection: 'Campaign Creation',
    campaign_type_selection_description_without_cpc:
      'Choose between a "Cost-per-Action Campaign" or "Cost-per-Mille Campaign". The type cannot be changed once the campaign has been created.',
    campaign_type_selection_description:
      'Choose between three different campaign types. The type cannot be changed once the campaign has been created.',
    campaign_type_conversion: 'Cost-per-Action Campaign',
    campaign_type_conversion_description:
      'The Cost-per-Action (CPA) campaign charges you only if your promoted products are actually sold, No sale, no cost. We bill you one bid per unit sold.',
    campaign_type_impression: 'Cost-per-Mille Campaign',
    campaign_type_impression_description:
      'The Cost-per-Mille (CPM) campaign helps you increase awareness of your advertisement. We bill you one bid per 1,000 views of your Ad.',
    campaign_type_click: 'Cost-per-Click Campaign',
    campaign_type_cpc_description:
      'The Cost-Per Click (CPC) campaign allows you to increase Shopper traffic to your branded pages. Accordingly, we bill based on the clicks to your branded page.',
    campaign_window: 'Campaign window',
    campaigns_overview: 'Campaigns Overview',
    campaign_approval: 'Campaign Approval',
    campaign_approval_description: 'Approve or reject submitted campaigns',
    campaign_paused_on_day: 'Campaign will be paused on this day',
    cancel: 'Cancel',
    cancelled: 'cancelled',
    cant_find_page: 'Oops!  We can’t seem to find the page you’re looking for.',
    category: 'Category',
    changed: 'changed',
    changelog_check_details_label: 'For details, see',
    changelog_removed_label: 'was removed',
    changelog_updated_label: 'was updated',
    changelog_universe_rules_field_label:
      'Properties of the assigned audience.',
    changelog: 'Changelog',
    change_password: 'Change Password',
    change_password_description:
      'Your password must be at least 6 characters long.',
    changed_to: 'changed to',
    channel: 'Channel',
    channels: 'Channels',
    channel_selection: 'Channel selection',
    channel_settings: 'Channel Settings',
    characters: 'characters',
    choose_file: 'Choose File',
    clear: 'Clear',
    clear_all: 'Clear all',
    click_to_calculate: 'Save changes to see the predicted value',
    click_to_calculate_cpa:
      'To see an estimation of the potentially purchased units of your campaign, fill in all of the following fields and click save: Products, Campaign Window, Budget and CPA bid.',
    click_to_calculate_cpm:
      'To see an estimation of the potential views of your campaign, fill in all of the following fields and click save: Products, Campaign Window, Budget, CPM bid and optionally your Frequency Cap.',
    clone_campaign: 'Clone Campaign',
    clone_campaign_confirmation_header: 'Clone Campaign "{{campaignName}}"?',
    clone_campaign_confirmation_message: `Do you want to clone the campaign '{{campaignName}}'? This will create a duplicate. For better identification, give it a suitable name.`,
    close: 'Close',
    company: 'Company',
    compare_with: 'Compare with',
    confirm: 'Confirm',
    confirm_activity_title: 'Confirm Activity',
    confirm_activity_message:
      'You will be logged out in {{remainingTime}} seconds, extend session?',
    confirm_channel_change_header: 'Confirm channel change?',
    confirm_channel_change_message:
      'Please note: If you change the channel, the selected products, audience and creative will be lost for this campaign. You can select them again.',
    confirm_country_change_header: 'Confirm country change?',
    confirm_country_change_message:
      'Please note that by changing the country, all added products will be lost and the campaign window is reset. Also, the channel, campaign group, audience, creative and budget information must be reselected.',
    confirm_deletion: 'Confirm deletion',
    confirm_retailer_change: 'Confirm retailer change?',
    cookie_notice: 'Cookie Notice',
    csp_generic_title: 'Campaign Success Prediction',
    csp_generic_tooltip:
      'Please be aware that the provided value is only a rough, non-binding estimation based on historical data and can therefore not entirely predict the success of future campaigns. Past promotion periods are not taken into account and may influence the result.  \r\nIf there is not enough historical purchase data available as a basis for the estimation, it will not show a value.',
    csp_no_estimation_for_imported_universe:
      'An estimation is not possible for manually uploaded test audiences.',
    csp_no_estimation_for_external_universe:
      'An estimation is not possible for imported audiences.',
    confirm_password: 'Confirm Password',
    control_group: 'Control Group',
    control_group_switched_on_changelog_label:
      'A/B test is active. Control group (no ad delivery) set to ',
    control_group_switched_off_changelog_label:
      'A/B test (with control group) is switched off',
    control_group_percentage_label:
      'Control Group (Percentage of shoppers with no ads)',
    control_group_size: 'Control Group Size',
    control_group_size_helper:
      'The Control group size (percentage). min 0 max 15',
    conversion: 'conversion',
    conversions: 'Conversions',
    conversion_rate: 'Conversion Rate',
    copied_to_clipboard: 'Copied to clipboard',
    copy: 'Copy',
    copy_campaign: 'Copy campaign?',
    copy_gtin: 'Copy GTIN',
    copy_campaign_description: `Please confirm that you would like to create a copy of '{{name}}'.`,
    count_added: '{{countValue}} added',
    country: 'Country',
    coupon: 'Coupon',
    create: 'Create',
    create_new_campaign: 'Create Campaign',
    create_new_campaign_group: 'Create Campaign Group',
    create_product: 'New product',
    create_product_set: 'New product set',
    create_universe: 'New audience',
    create_new_universe: 'Create New audience',
    creative: 'Creative',
    creative_ab_testing_checkbox: 'Use as A/B test campaign',
    creative_ab_testing_label: 'A/B Test Campaign',
    creative_ab_testing_validation:
      'A/B test campaigns must have budget, products, campaign window and an audience assigned.',
    creative_added: 'Creative added, please save the Campaign to confirm.',
    creatives: 'Creatives',
    creator: 'Creator',
    credit_limit: 'Credit Limit',
    daily: 'Daily',
    dashboard: 'Dashboard',
    day: 'day',
    day_plural: 'days',
    datepicker_start: 'Start',
    datepicker_end: 'End',
    default_state: 'Default State',
    delete: 'Delete',
    delete_campaign: 'Delete campaign?',
    delete_campaign_group: 'Do you really want to delete the campaign group?',
    delete_universe: 'Delete audience?',
    delete_campaigns: 'Delete campaigns?',
    delete_campaign_description: `Please confirm that you would like to permanently delete campaign '{{name}}'.`,
    delete_campaign_group_description:
      'Please note that when deleting the campaign group, the single campaigns lose their assignment and have to be reassigned. Do you want to continue?',
    delete_group: 'Delete group',
    delete_product: 'Delete product?',
    delete_product_set: 'Delete product set?',
    delete_product_description: `Please confirm that you would like to permanently delete product '{{name}}'.`,
    delete_product_set_description: `Please confirm that you would like to permanently delete product set '{{name}}'.`,
    delete_ruleset_confirmation_message:
      'Do you really want to delete this entire rule set?',
    delete_universe_description: `Please confirm that you would like to permanently delete audience '{{name}}'.`,
    delete_universe_no_running_campaigns_assigned: `No running campaigns assigns this audience.`,
    delete_universe_forbidden: `You can not permanently delete audience '{{name}}', because it has some running campaigns assigned:`,
    delivery: 'Execution',
    delivery_schedule: 'Execution Schedule',
    delete_user: 'Delete user?',
    delete_rule_set: 'Delete rule set',
    deleted_universe: 'Deleted Audience',
    delivery_schedule_manual: 'Manual',
    delivery_schedule_daily: 'Daily',
    delivery_schedule_fixed: 'Fixed',
    details: 'Details',
    description: 'Description',
    descriptions: 'Descriptions',
    disabled: 'Disabled',
    discard_prompt_header: 'Discard changes?',
    discard_prompt_header_selection: 'Discard selection?',
    discard_prompt_message:
      'Please note that if you continue, your current changes will be discarded. Do you want to continue?',
    discard_prompt_cancel_button_text: 'Keep editing',
    discard_prompt_confirm_button_text: 'Discard changes',
    distribution: 'distribution',
    distribution_dates: 'Distribution',
    done: 'Done',
    draft: 'Draft',
    drag_and_drop: 'Drag and drop a creative here or',
    edit: 'Edit',
    email: 'Email',
    edit_product: 'Edit product',
    edit_products: 'Edit products',
    edit_product_set: 'Edit product set',
    edit_universe: 'Edit Audience',
    enable_ab_test: 'Enable A/B Test',
    end_date: 'End Date',
    entries: 'Entries',
    error: 'Error',
    estimated: 'Estimated',
    execution: 'Execution',
    executions: 'Executions',
    execution_created: 'Execution created',
    execution_failed: 'execution failed',
    exclude: 'Exclude',
    exclusion_group: 'Exclusion Group',
    expected_costs: 'Expected costs (CPM)',
    expected_units_purchased: 'Expected units purchased',
    expected_units_purchased_info_tooltip:
      'The prediction considers the campaign duration, the selected products, the audience, the budget and Bid and assumes full distribution to all targeted shoppers. It solely relies on historical data and does not take into consideration Frequency Caps.',
    expected_views: 'Expected views',
    expected_views_info_tooltip:
      'The Prediction considers only the campaign duration and assumes full distribution to all targeted shoppers on each campaign day regardless of campaign competition or shopper behavior. Solely relies on historical data. Does not take into consideration Frequency Caps.',
    exportToCSV: 'Export to CSV',
    external_campaign_id: 'External Incentive ID',
    failed_universe_message:
      'Audience creation failed. Please select a different Audience',
    feature_toggles: 'Feature Toggles',
    file_too_big: 'File is too big. Max 2mb',
    first_name: 'First Name',
    fixed: 'Fixed',
    forBudget: 'For Budget',
    form_validation_error:
      'There are errors in the form, please correct them before continuing.',
    forgot_password: 'Forgot password?',
    filter_by: 'Filter by',
    frequency_cap: 'Frequency cap',
    frequency_cap_default: 'Use default uncapped frequency',
    frequency_cap_views: 'Show no more than',
    frequency_cap_time_unit: 'views per',
    from: 'from',
    global: 'Global',
    gtin: 'GTIN',
    group_revenue: 'Revenue',
    group_shoppers_reached: 'Shoppers Reached (Total)',
    group_units_purchased: 'Unit Purchased',
    group_used_budget: 'Budget Spent',
    group_roas: 'Group ROAS',
    group_views: 'Views',
    group_impressions: 'Impressions',
    group_clicks: 'Clicks',
    headline: 'Headline',
    headlines: 'Headlines',
    hide_budget_due_to_multiple_currencies_cg:
      'Cannot show the sum of budgets because of multiple currencies in this campaign group',
    id: 'Id',
    images: 'Images',
    imprint: 'Imprint',
    import_universe_upload_file_hint: 'Only CSV files are supported here',
    import_universe_upload_drag_and_drop: 'Drag and drop CSV file here or',
    imported_universe: 'Imported Audience',
    impression: 'impression',
    impressions: 'Impressions',
    impressions_per: 'impression(s) per',
    incentive: 'Incentive',
    incentive_distribution: 'Incentive Distribution',
    incentive_needed: 'Incentive needed',
    incentives_max_warning: '10 Incentives Maximum',
    include: 'Include',
    in_last: 'in last',
    in_progress: 'in progress',
    invite_user_success: 'User invited to join application',
    keyCloak_unavailable:
      'This service is currently unavailable because of a technical issue. Please try again later.',
    linked_campaigns: 'Linked Campaigns',
    label: 'Label',
    landscape: 'Landscape',
    last_event_time: 'Last Event Time',
    last_modified: 'Last modified',
    last_name: 'Last Name',
    last_updated: 'Last updated',
    limits: 'Limits',
    limit_distribution_to: 'Limit distribution to',
    linked_product_sets: 'Linked product sets',
    login: 'Login',
    logout: 'Logout',
    long_headline: 'Long Headline',
    add_universe: 'Add audience',
    manual: 'Manual',
    max_daily_budget: 'Max Daily Budget',
    max_incentive: 'Max Incentive Value',
    max_shopper_ids: 'max 3',
    more: 'more',
    mock_execution: 'Mock Execution',
    modified_on: 'Modified on',
    month: 'month',
    month_plural: 'months',
    multiple_currencies: 'multiple currencies',
    my_profile: 'My Profile',
    name: 'Name',
    name_of_the_campaign: 'The name of the campaign',
    next: 'Next',
    new_password: 'New Password',
    new_product: 'New Product',
    new_set: 'New Set',
    new_universe: 'new audience',
    no_campaign_in_group:
      'No entry available, please create or add a campaign.',
    no_campaigns_text:
      'It looks like you haven’t created any campaigns yet, to begin click here:',
    no_end_date: 'No End Date',
    no_reports_text: 'To use reporting feature please create campaigns first',
    no_results: 'No results',
    no_created: '{{no}} created',
    no_data: 'No Data',
    no_executions: 'No executions',
    no_linked_campaign: 'No linked campaigns',
    no_products: 'No products',
    no_promotion: 'No promotion',
    no_promotion_message:
      'This campaign resumes automatically as soon as the products of this campaign can be promoted again. You can still pause it manually.',
    no_selected_product_message:
      'No products selected. Please add the products you want to promote.',
    no_search_results: 'No search results',
    no_sales_data:
      "No estimation possible due to lack of previous sales in the selected audience. This won't affect the success of the campaign, you can still proceed and submit.",
    no_shoppers_in_universe: 'No shoppers in universe',
    no_universe_selected_message:
      'No audience selected. Please create or add a audience.',
    no_universe_for_country: 'No audience exists for the selected country!',
    not_available_attribute_message:
      'This attribute cannot be shown here because the data was updated by the system. But your audience is still valid.',
    number_of_conversions: 'No. Conversions',
    number_of_impressions: 'No. Impressions',
    number_of_shoppers: 'Number of shoppers',
    none: 'None',
    objective: 'Objective',
    of: 'of',
    old_password: 'Old Password',
    only_manually_created_universes: 'Only manually created audiences',
    out_of_budget: 'Out of budget',
    other_attributes: 'other attributes',
    other_products: 'other products',
    other_values: 'other values',
    paused: 'Paused',
    pause_campaign: 'Pause Campaign',
    phone: 'Phone Number',
    position: 'Position',
    predicted_header: 'Predicted {{objective}}',
    preparing: 'Preparing',
    preview: 'Preview',
    preview_email_template: '{{name}} Preview',
    preview_impulses: 'Preview Impulses',
    priority: 'Priority?',
    privacy_policy: 'Privacy Policy',
    proceed: 'Proceed',
    products: 'Products',
    products_of: 'Products of',
    product_name: 'Product Name',
    product_purchase: 'product purchase',
    product_set: 'Product Set',
    product_sets: 'Product Sets',
    product_id: 'Product ID',
    product_gtin: 'Product GTIN',
    products_empty_state_title: 'No entry available.',
    products_empty_state_subtitle: 'Please create a new product',
    profile: 'Profile',
    profile_updated: 'Profile updated',
    random: 'Random',
    ratio: 'Ratio',
    refresh: 'Refresh',
    reject: 'Reject',
    reject_campaign: 'Reject Campaign',
    reject_campaign_description:
      'Please leave a comment on why you want to reject the campaign. ',
    reject_reason: 'Reason of rejection',
    relative: 'Relative',
    reload: 'Reload',
    recommended_target_daily_budget:
      'Recommended daily budget for linear pacing {{currencySymbol}} {{target_daily_budget}}',
    remaining_budget: 'Remaining Budget',
    remove: 'Remove',
    remove_all: 'Remove All',
    remove_product_set: 'Remove product set',
    remove_universe: 'Remove audience',
    remove_campaign_from_group: 'Remove campaign from campaign group',
    reporting: 'Reporting',
    reset: 'Reset',
    results: 'Results',
    resume_campaign: 'Resume Campaign',
    retailer: 'Retailer',
    retailer_id: 'RETAILER',
    retailer_change_confirmation_message:
      'Please note that by changing the retailer, all added audience rules will be lost.',
    return_on_advertisement_spent: 'ROAS',
    revenue: 'Revenue',
    role: 'Role',
    rule: 'Rule',
    rules: 'Rules',
    rule_set: 'Rule set',
    running: 'Running',
    same_day: 'Same Day',
    save: 'Save',
    search: 'Search',
    search_for_campaign: 'Search for campaign',
    select: 'Select',
    select_advertiser: 'Select Advertiser',
    select_all: 'Select All',
    select_products: 'Select shoppers who bought...',
    selected: 'Selected',
    selected_products: 'Selected Products',
    select_a_universe: 'Please select an audience',
    send: 'Send',
    sets: 'Sets',
    settings: 'Settings',
    settings_updated: 'Settings updated',
    setup_incomplete: 'Setup incomplete',
    set_frequency_cap: 'Set frequency cap',
    shopper_engagement: 'Conversion Rate',
    shopper: 'Shopper',
    audience: 'Audience',
    audiences: 'Audiences',
    estimated_audience: 'Estimated audience',
    search_criteria: 'Search for criteria',
    see: 'See',
    select_all_prompt_header: 'Confirm selection',
    select_all_prompt_message:
      'By selecting all products (based on a filter or keyword), you will lose the products that you selected individually before. Do you want to proceed?',
    shoppers_found: 'shoppers found',
    shoppers_reached: 'Shoppers Reached (Total)',
    shoppers_reached_daily_avg: 'Shoppers Reached (Daily Average)',
    shopperId: 'Shopper ID',
    show_all: 'SHOW ALL',
    show_selected_only: 'show selected only',
    show_selection: 'only show selected campaigns',
    sort_by: 'Sort by',
    square: 'Square',
    start_date: 'Start Date',
    start_time: 'Start time',
    show_more: 'Show more',
    show_less: 'Show less',
    state: 'State',
    status: 'Status',
    large_universe_size_warning_message:
      'High number of products or rules in the audience, supporting features like Campaign Success Prediction will be affected.',
    stopped: 'Stopped',
    stop_campaign: 'Stop campaign?',
    stop_campaign_description: `Please confirm that you would like to stop this campaign '{{name}}'.`,
    submit: 'Submit',
    success: 'success',
    sum_of_budgets: 'Sum of budgets',
    table_view: 'Switch to table view',
    tags: 'Tags',
    target_daily_budget: 'Target daily budget',
    target_url: 'Target URL',
    target_url_text: 'Target URL Text',
    terms_and_conditions: 'Terms and Conditions',
    test_universe: 'Test Audience',
    tile_view: 'Switch to tile view',
    to: 'to',
    trend: 'Trend',
    trigger_execution: 'Trigger execution',
    try_link_on_top: 'Try one of the links from the top bar.',
    type: 'Type',
    user_management: 'User management',
    update: 'Update',
    upload: 'Upload',
    upload_file_hint: 'Please use a {{fileExtensions}} with {{aspectRatio}}',
    upload_file_max_size_error:
      'The file size exceeds {{fileSizeLimit}}kb, please select a smaller file size',
    upload_products: 'Upload products',
    unable_to_read_file: 'Unable to read the file, please select another file',
    unassign_campaign: 'Unassign Campaign',
    unassign_campaign_confirmation:
      'Do you really want to unassign the campaign?',
    unassign_campaign_description:
      'Please note that after the campaign has been unassigned, it must be reassigned in the campaign settings. ',
    unassigned: 'Unassigned',
    units_purchased: 'Units Purchased',
    universe: 'Audience',
    universe_created: 'Audience created',
    universe_filter_manually_created: 'Manually created',
    universe_filter_imported: 'Imported',
    universe_filter_uploaded_csv: 'Uploaded (.CSV)',
    universe_updated: 'Audience updated',
    universes: 'Audiences',
    universe_builder: 'Audience Builder',
    universe_builder_description:
      'Please select shoppers based on purchase history',
    universe_changelog_description:
      'Here you can see all the updates that were made to this audience.',
    universe_failed_creation: 'Audience creation has failed',
    universe_overview: 'Audience Overview',
    universe_rule_limit_reached:
      'Reached the maximum number of rules. To create a new rule, delete an existing one.',
    universe_rule_set_limit_reached:
      'Reached the maximum number of rule sets. To create a new set, delete an existing one.',
    universe_rules: 'Audience Rules',
    used_budget: 'Budget Spent',

    updated: 'updated',
    updated_by: 'Updated by',
    updated_to: 'Updated to',
    upload_universe: 'Upload Audience',
    uploaded_universe: 'Uploaded Audience',
    users: 'Users',
    user_deletion_confirmation:
      'Are you sure you want to delete the user {{userName}}?',
    user_deletion_success: 'User has been successfully deleted',
    validate: 'Validate',
    validate_campaign: 'Validate Campaign',
    validity: 'Attribution Window',
    view_all: 'View All',
    views: 'Views',
    view_campaign: 'View Campaign',
    view_table: 'View Table',
    view_tiles: 'View Tiles',
    withdrawSubmission: 'Withdraw Submission',
    week: 'week',
    week_plural: 'weeks',
    welcome: 'Welcome {{email}}',

    // headers
    create_campaign: 'Create {{type}} Campaign',
    edit_campaign: 'Edit Campaign',
    edit_campaign_group: 'Edit campaign group',
    create_campaign_group: 'Create campaign group',
    edit_campaign_group_subheader:
      'Define campaign group parameters, assign or unassign campaigns.',

    // subheaders
    subheader_add_campaign_to_group:
      'Here you can assign campaigns that have not yet been assigned to your campaign group.',
    subheader_add_user:
      'Add users here for them to be able to use this application. Adding will send an invite to them.',
    subheader_dashboard:
      'This is your current overview of the status of your campaigns.',
    // subheader_campaigns: 'Create new or edit existing campaigns.',
    subheader_campaigns:
      'Create new or edit existing campaigns and campaign groups.',
    subheader_edit_campaign: `Determine the campaigns and parameter setup.`,
    subheader_campaign_budget:
      'Set the budget for your campaign using the fields below',
    subheader_campaign_changelog:
      'The change log tracks all changes to a campaign (except changes of promoted products). This includes information about which field was changed and by whom, the old and new values of the field, the time of the change as well as the remaining budget.',
    subheader_campaign_channels:
      'You can choose where to distribute your ads by selecting a channel.',
    subheader_cpc_campaign_channels:
      'The event type “Click” required for CPC campaigns is not yet supported by any of the channels listed. In the meantime, you may use campaign creation and execution together with scripted API calls for testing.',
    subheader_campaign_creatives:
      'Upload the creative image you want to serve as ad to the shoppers. The size of the creative depends on the channel, different channels can have different requirements. The creative text is an option field that can be displayed in case the image is not shown.',
    subheader_campaign_products:
      'Select the products that you want to promote.',
    subheader_campaign_products_selection:
      'Select the products that you want to promote.',
    subheader_campaign_universes:
      'Audiences are where you can create your desired target group. Rules can be used to make complex selections possible, i.e. Rule 1:(include shoppers who bought x OR y OR z) AND Rule 2:(exclude shoppers who bought a OR b OR c). If you want to target all shoppers within your channel and country selection, you may leave the audience selection empty.',
    subheader_campaign_window:
      'In this step you define the duration of your campaign. Please note: the yellow pause icon indicates periods where your campaign cannot be distributed (eg. due to product unavailability), and will therefore automatically be paused by the system for the displayed period, and automatically resumed afterwards. Please first select a product you want to promote before selecting the campaign window. If you create the campaign and select the current day as the start day, be aware that campaigns approved after 12:00 UTC (noon) will not be executed on the same day.',
    subheader_frequency_cap:
      ' Frequency capping allows you to limit the number of views per shopper. For example: a frequency cap of 1 limits each shopper to view the ad only once.',
    subheader_profile:
      'Update you profile information and change your password.',
    subheader_reporting:
      'Review the performance of your active campaigns. To edit a campaign, click the corresponding row.',
    subheader_edit_product:
      'Enter a name and GTIN of a product you want to use in audiences or campaigns.',
    subheader_edit_product_set:
      'Products can grouped into product sets. A product set makes it easier for you reuse product with similar characteristics in audiences and campaigns.',
    subheader_universes:
      "Create specific audiences based on the shoppers' past purchase behavior.",
    subheader_universe_products_selection:
      'Include and exclude shoppers by selecting products they bought. Use the keyword search for searching through product description and GTINs or search and filter by attributes.',
    subheader_user_management: 'Manage your user accounts.',
    subheader_edit_universe:
      'To create an audience you can combine several rules and rule sets. Each rule states whether a shopper who matches a certain criterion is included in an audience or excluded from it. Rules can be combined into rule sets. In this case, the rules within a set are connected via an "AND", which means that all criteria must be met in order for the shopper to be selected. Rule sets are connected via an “OR” , meaning that shoppers are included in the audience if any of the rule sets apply.',
    subheader_import_universe:
      'You can upload a CSV with shopperIDs for test purposes here.',
    to_reporting: 'To Reporting',

    // login
    login_title: 'Welcome to the SMP Portal',
    login_subtitle:
      'Build optimized audiences for your ad campaigns and set up personalized offers for your consumers using advanced AI methods and retail data.',
    email_address: 'Email address',
    login_to_account: 'Login to account',
    login_directions: 'Please enter your email and password to continue',
    password: 'Password',
    password_changed: 'Password changed',
    password_description:
      'Choose a secure password and change your password regularly.',
    passwords_do_not_match: 'Passwords do not match',
    sign_in: 'Sign In',
    reset_password: 'Reset password',
    reset_password_directions:
      "Enter your email address and we'll send you a link to reset your password.",
    please_check_email: 'Please check your email',
    push_mode_info:
      'Features like frequency caps and target daily budget are limited to the channel latency of supplying event to the platform. Please contact support to understand more about this limitation of these channels.',
    back_to_login: 'Back to Login',

    // execution status
    submitted_impulse_calc: 'Submitted for Impulse Calculation',
    impulse_calc: 'Impulse Calculation',
    impulse_calc_error: 'Impulse Calculation Error',
    impulse_calc_success: 'Impulse Calculation Success',
    impulse_calc_cancelled: 'Impulse Calculation Cancelled',
    submitted_impulse_proc: 'Submitted for Impulse Processing',
    impulse_proc: 'Impulse Processing',
    impulse_proc_error: 'Impulse Processing Error',
    impulse_proc_cancelled: 'Impulse Processing Cancelled',
    impulse_proc_success: 'Delivery',

    // errors
    error_404: '404',
    error_general: 'Something went wrong',
    error_required: '{{entity}} is required',
    error_invalid_phone: 'Please enter a valid phone number',
    [ErrorCode.AUTH_USER_NOT_FOUND]:
      'User not found. This user may have been deleted.',
    [ErrorCode.AUTH_INVALID_EMAIL]: 'The email is incorrectly formatted',
    [ErrorCode.AUTH_WRONG_PASSWORD]: 'The password is invalid',

    // validation errors
    [Validation.NAME_TOO_LONG]:
      'The campaign name cannot be longer than 90 characters',
    [Validation.NAME_EMPTY]: 'The campaign must have a name',
    [Validation.MIN_BUDGET]:
      'The budget cannot be less than {{currencySymbol}} {{budget}}',
    [Validation.MIN_CPM_BID]:
      'The bid must be more than {{currencySymbol}} {{limit}}',
    [Validation.MIN_CPA_BID]:
      'The bid must be more than {{currencySymbol}} {{limit}}',
    [Validation.MIN_CPC_BID]:
      'The bid must be more than {{currencySymbol}} {{limit}}',
    [Validation.CHANNELS_EMPTY]: 'At least one channel must be assigned',
    [Validation.CHANNEL_RATIOS]: 'The sum of budget ratios must equal 100%',
    [Validation.CAMPAIGN_START_EMPTY]: 'The campaign must have a start date',
    [Validation.CAMPAIGN_START_PAST]:
      'The campaign start date must not be in the past',
    [Validation.CAMPAIGN_END_EMPTY]: 'The campaign must have a end date',
    [Validation.CAMPAIGN_END_PERIOD]: `The campaign duration must be at least ${MINIMUM_CAMPAIGN_DURATION_DAYS} days`,
    [Validation.REJECT_REASON_EMPTY]: 'You must enter a rejection reason',
    [Validation.ADVERTISER_PRODUCTS_EMPTY]:
      'At least one product must be assigned',
    [Validation.CREATIVE_EMPTY]: 'You must select a creative',
    [Validation.CREATIVE_TARGET_URL_INVALID]:
      'The target url must be allowed by the channel',
    [Validation.CREATIVE_TARGET_URL_MAX_LENGTH]: `The target url is too long, the maximum length is ${CREATIVE_TARGET_URL_MAX_LENGTH} characters`,
    [Validation.CREATIVE_TARGET_URL_EMPTY]: 'The target URL must have a value',
    [Validation.CREATIVES_COUNT_MISMATCH]: 'You must upload all creatives',
    [Validation.TARGET_DAILY_BUDGET_MIN]:
      'The target daily budget must be more than {{currencySymbol}} {{limit}}',
    [Validation.TARGET_DAILY_BUDGET_MAX]:
      'The target daily budget must be same as or lower than campaign budget',
    [Validation.CAMPAIGN_GROUP_EMPTY]: 'The campaign must have a group',
    [Validation.CREATIVE_ALTERNATIVE_TEXT_MAX_LENGTH]: `The creative text is too long, the maximum length is ${CREATIVE_ALTERNATIVE_TEXT_MAX_LENGTH} characters`,
    [Validation.UNIVERSE_BUILD_FAILED]:
      'Selected audience is not valid, please select a different one',
    [Validation.INCENTIVE_DISTRIBUTION_ZERO]:
      'Incentive distribution should be in range of 1-100',

    // Enums
    [UserRole.UserRoleAdvertiserAdmin]: 'Advertiser Admin',
    [UserRole.UserRoleAdvertiserCampaignManager]: 'Advertiser Campaign Manager',

    // reporting KPI tile tooltips
    reporting_impressions_description:
      'Number of rendered impression events, provided by the channel.',
    reporting_used_budget_description:
      'The sum of all billable events (view, click or purchase) multiplied by the respective bid (CPM, CPC or CPA) of the campaign.',
    reporting_revenue_description:
      'The total revenue with any Promoted Products (non-discounted product price times the quantity of items) generated by targeted shoppers, who received an ad impression, that occurs within the attribution window (14 days).',
    reporting_shoppers_reached_description:
      'The number of distinct shoppers that have generated a view event. This number is calculated as a total over the entire campaign duration. For campaign groups, only shoppers from the assigned CPM campaigns are considered.',
    reporting_units_purchased_description:
      'The total number of units purchased of any of the Promoted Products generated by targeted shoppers that occurs within the attribution window (14 days) following an exposure (rendered impression) from the campaign.',
    reporting_average_cpa_description: 'The average CPA per Units Purchased.',
    reporting_average_cpm_description: 'The average CPM per View.',
    reporting_views_description:
      'Number of Views of an ad as reported by the channel.',
    reporting_average_cpc_description: 'The average CPC per Click.',
    reporting_clicks_description:
      'Number of Clicks on an ad as reported by the channel.',
    reporting_group_used_budget_description:
      'The sum of all billable events (view, click or purchase) multiplied by the respective bid (CPM, CPC or CPA) of the campaign.',
    reporting_group_views_description:
      'Number of Views of an ad as reported by the channel. A View event is reported when more than 50% of the ad creative was viewable for at least 1s.',
    reporting_group_impressions_description:
      'Number of rendered impression events, provided by the channel.',
    reporting_group_revenue_description:
      'The total revenue with any Promoted Products (non-discounted product price times the quantity of items) generated by targeted shoppers, who received an ad impression, that occurs within the attribution window (14 days).',
    reporting_group_shoppers_reached_description:
      'The number of distinct shoppers that have generated a view event. This number is calculated as a total over the entire campaign duration. For campaign groups, only shoppers from the assigned CPM campaigns are considered.',
    reporting_group_units_purchased_description:
      'The total number of units purchased of any of the Promoted Products generated by targeted shoppers that occurs within the attribution window (14 days) following an exposure (rendered impression) from the campaign.',
    reporting_group_clicks_description:
      'Number of Clicks on an ad as reported by the channel.',
  },
};

export default en;
