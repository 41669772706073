import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { definitions } from 'types/api';

import { useStoreDispatch } from 'store';
import {
  setDraftTouchedFields,
  updateDraft,
} from 'store/modules/campaign/slice';

import { NumberFormat } from 'components/Shared';
import {
  NumberInput,
  TextInput,
  ValueLabel,
} from 'components/Shared/CustomMui/Form';

import {
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from '@mui/material';

import { CampaignSectionProps } from '../CampaignDetails';

interface Props extends Pick<CampaignSectionProps, 'validate'> {
  campaign_type: definitions['CampaignType'];
  external_campaign_id: string;
  max_incentive_value?: string;
  incentive_distribution?: number;
  all_creative_targets_defined: boolean;
  disabled?: boolean;
}

export const Incentives = ({
  campaign_type,
  external_campaign_id,
  max_incentive_value,
  incentive_distribution,
  all_creative_targets_defined,
  disabled,
  validate,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();

  const [isCouponNeeded, setIsCouponNeeded] = useState<boolean>(
    !!external_campaign_id
  );

  return (
    <>
      <Grid
        container
        rowSpacing={0}
        columnSpacing={{ xs: 0 }}
        direction="column"
      >
        <Grid item sx={{ mb: (theme) => theme.spacing(2) }}>
          <Typography
            variant="h3"
            sx={{
              fontSize: (theme) => theme.typography.body2.fontSize,
            }}
          >
            {t('incentive')}
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isCouponNeeded}
                  disabled={disabled}
                  onChange={(value) => {
                    setIsCouponNeeded(!isCouponNeeded);
                    dispatch(setDraftTouchedFields('incentive_distribution'));
                    if (value.target.checked) {
                      dispatch(
                        updateDraft({
                          external_campaign_id: '',
                          incentive_distribution: { value: 100 },
                        })
                      );
                    } else {
                      dispatch(
                        updateDraft({
                          external_campaign_id: '',
                          incentive_distribution: { value: 0 },
                        })
                      );
                    }
                  }}
                />
              }
              label={t('incentive_needed') as string}
            />
          </FormGroup>
        </Grid>
        <Grid item>
          <ValueLabel id={'max-incentive-value'} label={t('max_incentive')}>
            <NumberFormat
              displayType="text"
              value={Number(max_incentive_value)}
            />
          </ValueLabel>
        </Grid>
        <Grid item>
          <TextInput
            id="external-campaign-id"
            onChange={(e) =>
              dispatch(
                updateDraft({ external_campaign_id: e.target.value?.trim() })
              )
            }
            label={t('external_campaign_id')}
            value={external_campaign_id.trim()}
            disabled={!isCouponNeeded || disabled}
            width="100%"
          />
        </Grid>
        <Grid>
          <NumberInput
            id="incentive-distribution"
            label={t('incentive_distribution')}
            width="100%"
            prefix={!isCouponNeeded ? '' : '%'}
            disabled={!isCouponNeeded || disabled}
            max={100}
            min={1}
            value={incentive_distribution ?? ''}
            onChange={(e) =>
              isCouponNeeded &&
              dispatch(
                updateDraft({
                  incentive_distribution: {
                    value: Number(e.target.value?.trim()),
                  },
                })
              )
            }
            onBlur={() =>
              dispatch(setDraftTouchedFields('incentive_distribution'))
            }
            error={isCouponNeeded && validate('incentive_distribution')}
          />
        </Grid>
        {isCouponNeeded &&
        campaign_type === 'CAMPAIGN_TYPE_CONVERSION' &&
        incentive_distribution !== undefined &&
        incentive_distribution < 100 &&
        !all_creative_targets_defined ? (
          <Grid flex="row" className="warning">
            {t('ad_not_clickable_warning')}
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </>
  );
};
