import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import { definitions } from 'types/api';
import { YESTERDAY, localMiddayISO } from 'utils/time';

import { DraftCampaignType } from './helpers';

export const MINIMUM_CAMPAIGN_DURATION_DAYS = 3;
export const MINIMUM_CAMPAIGN_BUDGET = MINIMUM_CAMPAIGN_DURATION_DAYS * 100;
const MINIMUM_CPM = 7.5;
const MINIMUM_CPA = 0.5;
const MINIMUM_CPC = 0.01;
export const CREATIVE_TARGET_URL_MAX_LENGTH = 1000;
export const CREATIVE_ALTERNATIVE_TEXT_MAX_LENGTH = 300;
export const CREATIVE_FILE_MAX_SIZE_LIMIT = 500000;

type Campaign = DraftCampaignType;
type Validator = (campaign: Campaign) => Validations;
type Validators = { [key in keyof Partial<Campaign>]: Validator };

type Validations = Partial<{
  [key in Validation]: boolean | number[] | string | boolean[];
}>;

export type CampaignFieldValidations = {
  [key in keyof typeof validators]: Validations;
};

type ValidationParams = Required<definitions['MonetaryConfig']> & {
  max_target_daily_budget: number;
};

export enum Validation {
  NAME_EMPTY = 'V NAME_EMPTY',
  NAME_TOO_LONG = 'V NAME_TOO_LONG',
  MIN_BUDGET = 'V MIN_BUDGET',
  MIN_CPM_BID = 'V MIN_CPM_BID',
  MIN_CPA_BID = 'V MIN_CPA_BID',
  MIN_CPC_BID = 'V MIN_CPC_BID',
  CHANNELS_EMPTY = 'V CHANNELS_EMPTY',
  CHANNEL_RATIOS = 'V CHANNEL_RATIOS',
  CAMPAIGN_START_EMPTY = 'V CAMPAIGN_START_EMPTY',
  CAMPAIGN_START_PAST = 'V CAMPAIGN_START_PAST',
  CAMPAIGN_END_PERIOD = 'V CAMPAIGN_END',
  CAMPAIGN_END_EMPTY = 'V CAMPAIGN_END_EMPTY',
  ADVERTISER_PRODUCTS_EMPTY = 'V ADVERTISER_PRODUCTS_EMPTY',
  CREATIVE_EMPTY = 'V CREATIVE_EMPTY',
  CREATIVE_IMAGE_EMPTY = 'V CREATIVE_IMAGE_EMPTY',
  CREATIVE_TARGET_URL_EMPTY = 'V CREATIVE_TARGET_URL_EMPTY',
  CREATIVE_TARGET_URL_INVALID = 'V CREATIVE_TARGET_URL_INVALID',
  CREATIVE_TARGET_URL_MAX_LENGTH = 'V CREATIVE_TARGET_URL_MAX_LENGTH',
  CREATIVE_ALTERNATIVE_TEXT_MAX_LENGTH = 'V CREATIVE_ALTERNATIVE_TEXT_MAX_LENGTH',
  CREATIVES_COUNT_MISMATCH = 'V CREATIVES_COUNT_MISMATCH',
  INCENTIVE_DISTRIBUTION_ZERO = 'V INCENTIVE_DISTRIBUTION_ZERO',
  REJECT_REASON_EMPTY = 'V REJECT_REASON_EMPTY',
  TARGET_DAILY_BUDGET_MIN = 'V TARGET_DAILY_BUDGET_MIN',
  TARGET_DAILY_BUDGET_MAX = 'V TARGET_DAILY_BUDGET_MAX',
  CAMPAIGN_GROUP_EMPTY = 'V CAMPAIGN_GROUP_EMPTY',
  UNIVERSE_BUILD_FAILED = 'V UNIVERSE_BUILD_FAILED',
}

const validateCampaignWindow = (campaign: Campaign): Validations => {
  if (
    campaign?.state !== 'CAMPAIGN_STATE_DRAFT' &&
    campaign?.state !== 'CAMPAIGN_STATE_REJECTED'
  ) {
    return {
      [Validation.CAMPAIGN_START_EMPTY]: false,
      [Validation.CAMPAIGN_START_PAST]: false,
      [Validation.CAMPAIGN_END_PERIOD]: false,
      [Validation.CAMPAIGN_END_EMPTY]: false,
    };
  }

  const { campaign_start, campaign_end } = campaign;
  const minEnd = addDays(
    !campaign_start ? new Date() : parseISO(campaign_start),
    MINIMUM_CAMPAIGN_DURATION_DAYS - 1
  );
  return {
    [Validation.CAMPAIGN_START_EMPTY]: !campaign_start,
    [Validation.CAMPAIGN_END_EMPTY]: !campaign_end,
    [Validation.CAMPAIGN_START_PAST]: !isAfter(
      parseISO(campaign_start || ''),
      YESTERDAY
    ),
    [Validation.CAMPAIGN_END_PERIOD]:
      !!campaign_end && isBefore(parseISO(campaign_end), minEnd)
        ? localMiddayISO(minEnd)
        : false,
  };
};

const getActiveChannel = (campaign: Campaign) => {
  return campaign.extended_channels?.find(
    (ch) => ch.id === campaign.channels[0]?.channel_id
  );
};

export const getCampaignValidationLimits = (
  campaign: Campaign
): ValidationParams => {
  const channel = getActiveChannel(campaign);
  const countryMonetaryConfig =
    channel?.monetary_configs?.[campaign.country_code];

  return {
    min_cpm_bid: countryMonetaryConfig?.min_cpm_bid ?? MINIMUM_CPM,
    min_cpc_bid: countryMonetaryConfig?.min_cpc_bid ?? MINIMUM_CPC,
    min_cpa_bid: countryMonetaryConfig?.min_cpa_bid ?? MINIMUM_CPA,
    min_budget: countryMonetaryConfig?.min_budget ?? MINIMUM_CAMPAIGN_BUDGET,
    min_daily_budget: 0,
    max_target_daily_budget: campaign?.budget ? Number(campaign.budget) : 0,
  };
};

export const validators: Validators = {
  budget: (campaign: Campaign) => ({
    [Validation.MIN_BUDGET]:
      Number(campaign.budget) <
      getCampaignValidationLimits(campaign).min_budget +
        Number(campaign.used_budget_total_cumulative || 0),
  }),
  promoted_products: (campaign: Campaign) => ({
    [Validation.ADVERTISER_PRODUCTS_EMPTY]:
      campaign.promoted_products?.length === 0,
  }),
  advertiser_cpm_bid: (campaign: Campaign) => ({
    [Validation.MIN_CPM_BID]:
      campaign.type === 'CAMPAIGN_TYPE_IMPRESSION'
        ? Number(campaign.advertiser_cpm_bid) <
          getCampaignValidationLimits(campaign).min_cpm_bid
        : false,
  }),
  advertiser_cpa_bid: (campaign: Campaign) => ({
    [Validation.MIN_CPA_BID]:
      campaign.type === 'CAMPAIGN_TYPE_CONVERSION'
        ? Number(campaign.advertiser_cpa_bid) <
          getCampaignValidationLimits(campaign).min_cpa_bid
        : false,
  }),
  advertiser_cpc_bid: (campaign: Campaign) => ({
    [Validation.MIN_CPC_BID]:
      campaign.type === 'CAMPAIGN_TYPE_CLICK'
        ? Number(campaign.advertiser_cpc_bid) <
          getCampaignValidationLimits(campaign).min_cpc_bid
        : false,
  }),
  campaign_start: (campaign: Campaign) => validateCampaignWindow(campaign),
  campaign_end: (campaign: Campaign) => validateCampaignWindow(campaign),
  channels: (campaign: Campaign) => {
    return {
      [Validation.CHANNELS_EMPTY]: campaign.channels.length === 0,
    };
  },
  name: (campaign: Campaign) => ({
    [Validation.NAME_EMPTY]: campaign.name.length === 0,
    [Validation.NAME_TOO_LONG]: campaign.name.length > 90,
  }),
  creatives: (campaign: Campaign) => {
    const channel = getActiveChannel(campaign);

    let creativeTargetURLTooLong: boolean[] = [];
    let creativeTargetURLInvalid: boolean[] = [];
    let creativeTargetURLEmpty: boolean[] = [];
    let creativeAltTextTooLong: boolean[] = [];
    let creativeImageEmpty: boolean[] = [];

    const allowed_target_url_origins =
      channel?.allowed_target_url_origins || [];

    campaign.creatives.forEach((creative, index) => {
      // validate alternative text length
      creativeAltTextTooLong[index] = !!(
        creative.description &&
        creative.description.length > CREATIVE_ALTERNATIVE_TEXT_MAX_LENGTH
      );

      // validate target url empty
      if (campaign.type === 'CAMPAIGN_TYPE_CLICK') {
        creativeTargetURLEmpty[index] = !creative.target_url;
      } else creativeTargetURLEmpty[index] = false;

      // validate target url value
      creativeTargetURLTooLong[index] = !!(
        creative.target_url &&
        creative.target_url.length > CREATIVE_TARGET_URL_MAX_LENGTH
      );
      creativeTargetURLInvalid[index] = !!(
        creative.target_url &&
        !allowed_target_url_origins?.some((origin) => {
          const validUrlPattern = new RegExp(`^${origin}(?:$|/|#|\\?)`);
          return validUrlPattern.test(creative.target_url);
        })
      );

      // validate image empty
      creativeImageEmpty[index] = !creative.url && !creative?.file;
    });

    return {
      [Validation.CREATIVE_EMPTY]:
        campaign.creatives.length === 0 || !campaign.creatives[0]?.url,
      [Validation.CREATIVES_COUNT_MISMATCH]:
        campaign.creatives.filter((creative) => creative.url || creative?.file)
          .length !==
        (channel?.creative_options?.length || 1) *
          (campaign.country?.languages?.length || 1),
      [Validation.CREATIVE_TARGET_URL_INVALID]: creativeTargetURLInvalid,
      [Validation.CREATIVE_TARGET_URL_EMPTY]: creativeTargetURLEmpty,
      [Validation.CREATIVE_TARGET_URL_MAX_LENGTH]: creativeTargetURLTooLong,
      [Validation.CREATIVE_ALTERNATIVE_TEXT_MAX_LENGTH]: creativeAltTextTooLong,
      [Validation.CREATIVE_IMAGE_EMPTY]: creativeImageEmpty,
    };
  },

  reject_reason: (campaign: Campaign) => ({
    [Validation.REJECT_REASON_EMPTY]:
      campaign.reject_reason?.trim().length === 0,
  }),

  target_daily_budget: (campaign: Campaign) => ({
    [Validation.TARGET_DAILY_BUDGET_MAX]: [
      'CAMPAIGN_TYPE_IMPRESSION',
      'CAMPAIGN_TYPE_CLICK',
    ].includes(campaign.type)
      ? Number(campaign.target_daily_budget) >
        getCampaignValidationLimits(campaign).max_target_daily_budget
      : false,
    [Validation.TARGET_DAILY_BUDGET_MIN]: [
      'CAMPAIGN_TYPE_IMPRESSION',
      'CAMPAIGN_TYPE_CLICK',
    ].includes(campaign.type)
      ? Number(campaign.target_daily_budget) <
        getCampaignValidationLimits(campaign).min_daily_budget
      : false,
  }),
  campaign_group_id: (campaign: Campaign) => ({
    [Validation.CAMPAIGN_GROUP_EMPTY]: !campaign.campaign_group_id,
  }),
  universe_id: (campaign: Campaign) => ({
    [Validation.UNIVERSE_BUILD_FAILED]:
      campaign.universe?.build?.status === 'UNIVERSE_BUILDER_STATUS_FAIL',
  }),
  incentive_distribution: (campaign: Campaign) => ({
    [Validation.INCENTIVE_DISTRIBUTION_ZERO]:
      !!campaign?.external_campaign_id &&
      campaign?.incentive_distribution?.value === 0,
  }),
};

export const validateCampaign = (
  campaign: Campaign
): CampaignFieldValidations => {
  return Object.values(validators)
    .map((f) => ({
      [f.name]: (f as Validator)(campaign),
    }))
    .reduce<Validations>((a, nv) => {
      return {
        ...a,
        ...nv,
      };
    }, {}) as CampaignFieldValidations;
};

export const isCampaignValid = (campaign: Campaign): boolean => {
  return !Object.values(validateCampaign(campaign)).filter((v) => !!v).length;
};
