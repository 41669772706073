import { ChangeEvent, FocusEvent, ReactNode } from 'react';

import {
  FormHelperText,
  InputBaseComponentProps,
  OutlinedInput,
  OutlinedInputProps,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

import { InputS } from './Form.style';
import { FormError } from './FormError';
import { FormLabel } from './FormLabel';

export type InputValidationMessage = null | boolean | string | undefined;

const FormHint: React.FC = ({ children }) => (
  <FormHelperText
    sx={{
      fontSize: 12,
      marginTop: (theme) => theme.spacing(0),
      marginBottom: (theme) => theme.spacing(0.5),
    }}
  >
    {children}
  </FormHelperText>
);

export interface TextInputProps extends Omit<OutlinedInputProps, 'error'> {
  className?: string;
  disabled?: boolean;
  error?: InputValidationMessage;
  hint?: string | ReactNode;
  inputProps?: InputBaseComponentProps;
  label: string;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: string;
  id?: string;
  width?: number | string;
  sx?: SxProps<Theme>;
}
export const TextInput: React.FC<TextInputProps> = (props) => {
  const {
    className,
    disabled,
    inputProps,
    label,
    error,
    hint,
    onBlur,
    onChange,
    placeholder,
    value,
    id,
    width,
    sx,
    ...rest
  } = props;
  return (
    <InputS className={className} width={width} sx={sx}>
      <FormLabel id={id} label={label} required={rest.required} />
      <OutlinedInput
        id={id}
        disabled={disabled}
        error={!!error}
        fullWidth
        inputProps={inputProps}
        margin="dense"
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        {...rest}
      />
      {!!error && typeof error === 'string' && <FormError>{error}</FormError>}
      {!!hint && !error && <FormHint>{hint}</FormHint>}
    </InputS>
  );
};
